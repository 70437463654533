import Layout from "./Layout/Layout";
import Display from "./components/Display";
import Header from "./components/Header";
export default function App() {
  return (
    <Layout>
      <Header />
      <Display />
    </Layout>
  );
}

