import Constantes from "./constantes";
import {servicos} from "./work";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

export default function Servicos() {
  return (
    <div
      id="servicos"
      className="flex flex-wrap flex-row justify-center gap-6 ml-8 max-w-screen-xl w-full ml-auto mr-auto"
    >
      <div
        className="font-extrabold py-5 mt-10 text-5xl"
        data-aos={"slide-up"}
        data-aos-duration={"700"}
      >
          <p class="px-4">Prestadores de Serviços</p>
        <div className="flex flex-col text-center">
        <a
          href={require("../../assets/files/regras.pdf")}
          download={"regras"}
          class="inline-flex text-center px-6 py-2 mt-5 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
          >
            Regras.
            <CloudDownloadIcon />
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=5511999709020"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Empreendedor, entre em contato e insira seu negocio.
            <WhatsAppIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/HFv1PchEvvS6uhG7o3FmIl"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo de Compra e Venda do Condomínio.
            <WhatsAppIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/JdRxA9QdU8d7l9uVG3YB1v"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo de Compra e Venda do Condomínio.
            <WhatsAppIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/KLU9DCQ6tKB8oEq4RPNhuo"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo apenas para comunicação dos moradores.
            <WhatsAppIcon />
        </a>
       </div>
      </div>

      <div className="flex flex-wrap flex-row justify-center w-full p-4 gap-12">
        {servicos.map((servicos, ind) => {
          return (
            <div
              key={ind}
              data-aos={"zoom-in-up"}
              data-aos-offset="-5"
              data-aos-delay={ind % 2 === 0 ? "0" : "700"}
              data-aos-duration="2000"
            >
              <Constantes
                title={servicos.title}
                desc={servicos.desc}
                img={servicos.img}
                link={servicos.link}
                code={servicos.code}
                tags={servicos.tags}
              />
            </div>
          );
        })}
      </div>
      <div className="flex flex-col text-center">
        <a
          href={require("../../assets/files/regras.pdf")}
          download={"regras"}
          class="inline-flex text-center px-6 py-2 mt-12 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
          >
            Regras.
            <CloudDownloadIcon />
        </a>
        <a
          href="mailto:eltonrochadealmeida@gmail.com"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Duvidas? Envie um e-mail.
            <EmailIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/HFv1PchEvvS6uhG7o3FmIl"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo de Compra e Venda do Condomínio.
            <WhatsAppIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/JdRxA9QdU8d7l9uVG3YB1v"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo de Compra e Venda do Condomínio.
            <WhatsAppIcon />
        </a>
        <a
          href="https://chat.whatsapp.com/KLU9DCQ6tKB8oEq4RPNhuo"
          target="_blank"
          rel="noreferrer"
          class="inline-flex text-center px-6 py-2 mt-2 mx-auto w-fit text-sm font-medium border rounded-lg  focus:z-10 focus:ring-4 focus:outline-none focus:text-blue-700 bg-gray-800 text-gray-400 border-gray-600 hover:text-white hover:bg-gray-700 focus:ring-gray-700"
        >
            Grupo apenas para comunicação dos moradores.
            <WhatsAppIcon />
        </a>
       </div>
    </div>
  );
}