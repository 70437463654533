import "../../App.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LottiePlayer from "@lottiefiles/lottie-player";
import EmailIcon from "@mui/icons-material/Email";
import TouchAppIcon from '@mui/icons-material/TouchApp';

export default function Construir() {
  return (
    <div
      id="construir"
      className="min-h-screen bg-white flex flex-col text-center gap-5 text-black text-lg  font-normal"
    >
      <div className="head text-5xl mt-12 font-bold" data-aos={"slide-down"}>
        Em Construção!
      </div>
      <div className="flex flex-wrap flex-row gap-6 ml-8 max-w-screen-xl w-full ml-auto mr-auto">
        <div className="flex-col mt-14 flex flex-auto w-64 gap-6">
          <h3 className="text-3xl font-medium px-4" data-aos={"fade-left"}>
            O que esta sendo feito de <span className="text-green-600">NOVO</span>?
          </h3>
          <p
            className="pt-5 leading-7 text-slate-900 text-justify px-4"
            data-aos={"fade-left"}
          >
            Mas antes aqui vai um pensamento.<br/>
            Não há limites para onde uma idéia possa leva-lo, mesmo assim, uma idéia sozinha não é suficiente.
            Idéias são inúteis se alguém não executá-las, por si só, uma idéia não é capaz de fazer nada,
            não é capaz de levá-lo ao sucesso. Ela só passa a ter valor quando ações correspondentes são
            realizadas. Veja, você provavelmente já teve centenas ou milhares de boas idéias, e as únicas que
            serviram para algo foram as que tiveram ação conectada ou causada pôr elas. Idéias criam valor quando
            investimos nelas nossas ações e de fato quanto mais você age sobre suas idéias, melhor essas idéias
            acabam ficando. Uma idéia é um ótimo começo, mas só se você agir!
            <br/>
            <span className="text-blue-600">(Fonte: Sucesso Powerminas)</span>
          </p>
          <p
            className="text-justify leading-7 text-slate-900 px-4"
            data-aos={"fade-right"}
          >
            <span className="text-yellow-600 font-bold">Lista sobre o que esta sendo construido no site!</span><br/>
            <span className="font-bold">- Mural de Avisos:</span> Vamos integrar os avisos condominiais
             junto com o site. <br/>
            <span className="font-bold">- Aluguel da Churraqueira:</span> Sera criado uma area de login para 
            que o condomino possa realizar seu contato a alugar. <br/>
            <span className="font-bold">- Integração com a Portaria:</span> Em contato com o Sindico atual
             existe uma necessidade de automatizar o recebimento de produtos e ele, no momento, optou em
              realizar esta integração ja no site onde sera criado uma area de login para a portaria. <br/>
          </p>
          <p
            className="text-left leading-7 text-slate-900 px-4"
            data-aos={"fade-right"}
          >
            Quer ajudar na pagina?<br/>
                <button className="py-1 mt-2 text-white mx-auto px-2 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
                <a href="mailto:eltonrochadealmeida@gmail.com">
                  Fale com os desenvolvedores!
                  <EmailIcon />
                </a>
              </button><br/>
              <button className="py-1 mt-2 text-white mx-auto px-2 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
                <a href="https://eltonrocha.com.br/">
                  Pagina do criador do projeto.
                  <TouchAppIcon />
                </a>
              </button>
            </p>
            <br/>
        </div>
        <div
          class="relative flex-auto w-32 sm:rounded-lg  pl-4"
          data-aos={"slide-left"}
        >
          <div data-aos={"fade-left"}>
            <lottie-player
              autoplay
              loop
              mode="bounce"
              src="https://lottie.host/1359db72-854e-4c53-879e-a7d252edee14/0mfBTFZBus.json"
              style={{ width: "450px" }}
            />
          </div>
          <button className="py-1 mt5 text-white mx-auto px-2 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
            <a href="https://api.whatsapp.com/send?phone=5511999709020">
              Contribua com sua ideia!
              <WhatsAppIcon />
            </a>
          </button>
        </div>
      </div>
      <br/>
    </div>
  );
}
