import "../../App.css";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import TouchAppIcon from '@mui/icons-material/TouchApp';

export default function Sobre() {
  return (
    <div
      id="sobre"
      className="min-h-screen bg-white flex flex-col text-center gap-5 text-black text-lg  font-normal px-4"
    >
      <div className="head text-5xl mt-12 font-bold" data-aos={"slide-down"}>
        Condomínio Fit Taboão
      </div>
      <div className="flex flex-wrap flex-row gap-6 ml-8 max-w-screen-xl w-full ml-auto mr-auto">
        <div className="flex-col mt-14 flex flex-auto w-64 gap-6">
          <h3 className="text-3xl font-medium" data-aos={"fade-left"}>
            Resumo sobre <span className="text-yellow-600">Fit Taboão</span> e o site.
          </h3>
          <p
            className="pt-5 leading-7 text-slate-900 text-justify"
            data-aos={"fade-left"}
          >
            Este condomínio existe a mais de 14 anos, localizado estratégicamente em um excelente ponto
            geográfico, acessível para vários lugares da região metropolitana, com boas areas de lazer,
            proximo ao shopping e centro do município local.
          </p>
          <p
            className="text-justify leading-7 text-slate-900"
            data-aos={"fade-right"}
          >
            O objetivo deste portal é ajudar e conectar os condôminos, compartilhar informações
            sobre o condomínio, somar com grupos de vendas de WhatsApp interno
            dando uma visibilidade maior dos seus produtos e serviços. 
          </p>
          <p
            className="text-left leading-7 text-slate-900"
            data-aos={"fade-right"}
          >
            Manual de boas praticas e convivência!<br/>
                <button className="py-1 mt-2 text-white mx-auto px-2 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
                <a
                  href={require("../../assets/files/manual_convivencia.pdf")}
                  download={"manual_convivencia.pdf"}
                >
                  Leia as dicas.
                  <TouchAppIcon />
                </a>
              </button>
            </p>
            <br/>
        </div>
        <div
          class="relative flex-auto w-32   sm:rounded-lg  pl-4"
          data-aos={"slide-left"}
        >
          <div className="motion-safe:animate-zoomy">
            <img
              src={require("../../assets/images/foto_fit_2.jpg")}
              alt="Condominio Fit Taboão"
              className="rounded-full border-solid cursor-pointer  border-[8px] border-stone-600 min-h-fit mx-auto  max-w-[370px]"
            />
          </div>
          <button className="py-1 mt-3 text-white mx-auto px-2 bg-blue-600 border-2 w-fit border-blue-500 rounded-3xl  hover:-translate-y-1.5 duration-[350ms] hover:duration-[350ms] hover:bg-blue-800 hover:scale-[1.023] focus:bg-blue-800 animate-zoomy">
            <a
              href={require("../../assets/files/planta_tenda.pdf")}
              download={"planta_tenda.pdf"}
            >
              Planta do Apartamento
              <CloudDownloadIcon />
            </a>
          </button><br/>
        </div>
      </div>
      <br/>
    </div>
  );
}
