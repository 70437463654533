import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import Sidebar from '../Sidebar';
import { Container } from "./styles";

export default function Header() {
  const [ sidebar, setSidebar ] = useState(false);
  const showSidebar = () => setSidebar(!sidebar)

  return (
    <Container>
      <FaBars onClick={showSidebar} />

      {sidebar && <Sidebar active={setSidebar} />}
    </Container>
  );
}