export const servicos = [

  {
    title: "Curso de Excel | Basico ao Intermediário",
    desc: "Esta cansado de passar vergonha no trabalho? Não consegue entender como funciona o excel? Me chame, trabalho na área de Contabilidade Fiscal e sou mentora no Excel, posso te ajudar a dominar esta incrivel ferramenta. O curso é online via meeting e 100% mão na massa. Clique acima no contato.",
    tags: [
      {
        name: "office",
        color: "blue-600",
      },
      {
        name: "empresa",
        color: "yellow-600",
      },
      {
        name: "microsoft",
        color: "green-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/cintia-excel.webp",
    link: "https://api.whatsapp.com/send?phone=5511980672260",
  },

  {
    title: "R&M Design Vidros",
    desc: "Instalação e manutenção de box, sacada, janelas, espelhos e vidros em geral. Peça um orçamento no link acima de contato.",
    tags: [
      {
        name: "reformas",
        color: "blue-600",
      },
      {
        name: "vidros",
        color: "yellow-600",
      },
      {
        name: "manutenção",
        color: "green-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/regi-vidros.jpg",
    link: "https://api.whatsapp.com/send?phone=5511946465829",
  },

  {
    title: "Serviços de Informática",
    desc: "Desde o básico até o avançado, confiabilidade e transparência nos serviços, técnico com anos de experiência na área e formação especializada. Precisa de ajuda? Entre em contato acima e faça já seu orçamento com preço justo!",
    tags: [
      {
        name: "manutenção",
        color: "blue-600",
      },
      {
        name: "empresa",
        color: "yellow-600",
      },
      {
        name: "serviços",
        color: "green-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/informatica.jpg",
    link: "https://api.whatsapp.com/send?phone=5511968901113",
  },

  {
    title: "Açaiteria G3",
    desc: "Cardápio e Delivery em Taboão da Serra, faça seu pedido online no botão de contato acima.",
    tags: [
      {
        name: "doces",
        color: "blue-600",
      },
      {
        name: "sorvetes",
        color: "yellow-600",
      },
      {
        name: "gelados",
        color: "green-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/gustavo-acai.jpg",
    link: "https://acai-g3.goomer.app",
  },

  {
    title: "Sara Bolos",
    desc: "Bolos e pães caseiros igual da vovó, feito com todo carinho e amor. Doces diversos para matar aquea vontade de docinho. Brigadeiros de vários sabores para você adoçar a vida Montamos cestas de café da manhã e efetuamos a entrega.",
    tags: [
      {
        name: "doces",
        color: "blue-600",
      },
      {
        name: "pães",
        color: "yellow-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/sara1.png",
    link: "https://api.whatsapp.com/send?phone=5511933935342",
  },

  {
    title: "Sara Presentes",
    desc: "Embrulho, cestas de café e tábua de frios. Presenteie quem você tanto ama com os nossos serviços",
    tags: [
      {
        name: "papelaria",
        color: "green-600",
      },
      {
        name: "presentes",
        color: "blue-600",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/sara2.png",
    link: "https://api.whatsapp.com/send?phone=5511933935342",
  },

  {
    title: "Studio Saray Massagem",
    desc: "Aqui trabalhamos para total relaxamento de seu corpo e mente. Contra o estresse, cansaço mental, fisíco, dores musculares, retenção de liquido, dores na lombar, ciático. Agenda aberta, então vamos marcar um horário para cuidar de sua saúde.",
    tags: [
      {
        name: "massagem",
        color: "red-600",
      },
      {
        name: "fisioterapia",
        color: "green-600",
      },
      {
        name: "bem-estar",
        color: "yellow-600",
      },
      {
        name: "drenagem",
        color: "blue-800",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/sara3.png",
    link: "https://api.whatsapp.com/send?phone=5511933935342",
  },

  {
    title: "Elton Consultoria em TI",
    desc: "Sou Analista de Sistemas DevOps, apaixonado por tecnologia, cativado pela área de desenvolvimento, engenharia e operações, venho me aprimorando mais em arquitetura, desenvolvimento, IaC, PaaS e SaaS. Conheça mais sobre meu trabalho e incie sua consultoria, clique acima em contato.",
    tags: [
      {
        name: "consultoria",
        color: "red-600",
      },
      {
        name: "desenvolvimento",
        color: "green-600",
      },
      {
        name: "operação",
        color: "yellow-600",
      },
      {
        name: "tecnologia",
        color: "blue-800",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/bl19ap01.png",
    link: "https://eltonrocha.com.br/",
  },


]
