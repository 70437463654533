import styled from 'styled-components';

export const Container = styled.div`
  background-color: #171923;
  position: fixed;
  height: 100%;
  top: 0px;
  left: 0px;
  /* width: 15%; */
  left: ${props => props.sidebar ? '0' : '-100%'};
  animation: showSidebar .4s;
  z-index: 9999;

  > svg {
    position: fixed;
    color: orange;
    width: 30px;
    height: 30px;
    margin-top: 32px;
    margin-left: 32px;
    cursor: pointer;
  }

  @keyframes showSidebar {
    from {
      opacity: 0;
      width: 0;
    }
    to {
      opacity: 1;
      width: 140px;
    }
  }
`;

export const Content = styled.div`
  margin-top: 100px;
`;

export const ItemLink = styled.section `
   display: flex;
  align-items: center;
  background-color: #1A202C; 
  font-size: 20px;
  color: white;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  margin: 0 15px 20px;

  p {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 14px;
  }

  > svg {
    margin: 0 20px;
  }

  &:hover {
    background-color: black;
  }
`;