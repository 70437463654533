export const externos = [


  {
    title: "La Casa De Baterias",
    desc: "Venda, reparos, delivery, suporte e troca de baterias automotivas. Trabalhamos com todas as marcas para todos os carros nacionais e importados.",
    tags: [
      {
        name: "carro",
        color: "red-600",
      },
      {
        name: "suporte",
        color: "green-600",
      },
      {
        name: "emergência",
        color: "yellow-600",
      },
      {
        name: "reparo",
        color: "blue-800",
      },
    ],
    img: "https://raw.githubusercontent.com/eltongit/fit-taboao/main/imagens/baterias.png",
    link: "https://api.whatsapp.com/send?phone=5511949356031",
  },
]