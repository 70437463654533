import "../App.css";
import Inicio from "./Inicio";
import Sobre from "./Sobre";
import Externos from "./Externos";
import Mural from "./Mural";
import Servicos from "./Servicos";
import Contato from "./Contato";
import Construir from "./Construir";

export default function Display() {
  return (
    <div className="w-screen back text-white  ">
      <Inicio />
      <Sobre />
      <Servicos />
      <Externos />
      <Mural />
      <Contato />
      <Construir />
    </div>
  );
}
