import React from 'react'
import {useState} from "react";

import { 
  FaTimes, 
  FaHome, 
  FaUserAlt, 
  FaChartBar,
  FaPhone,
  FaLightbulb,
  FaCartPlus,
  FaClipboardList
} from 'react-icons/fa'

import {
  FiSettings
} from 'react-icons/fi'

import { Container, Content, ItemLink } from './styles'

const Sidebar = ({ active }) => {

  const closeSidebar = () => {
    active(false)
  }
  const [select, setSelect] = useState(0);
  return (
    <Container sidebar={active}>
      <FaTimes onClick={closeSidebar} />  
      <Content>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}
          >
            <FaHome />
            <a href="#inicio">Ínicio</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FaChartBar />
          <a href="#sobre">Sobre</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FiSettings />
          <a href="#servicos">Internos</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FaCartPlus />
          <a href="#externos">Externos</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FaClipboardList />
          <a href="#mural">Mural</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FaPhone />
          <a href="#contato">Contato</a>
          </p>
        </ItemLink>

        <ItemLink>
          <p
            onClick={() => setSelect(0)}
            className={`cursor-pointer hover:text-green-600 hover:-translate-y-0.5 hover:text-xl transition hover:transition ${select === 0 ? "text-blue-600 " : ""}`}            >
          <FaLightbulb />
          <a href="#construir">Em Breve</a>
          </p>
        </ItemLink>

      </Content>
    </Container>
  )
}

export default Sidebar